<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Gestión de Limpieza e Higiene</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" :to="{name: 'Limpieza e Higiene'}">
                                    <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader  color="primario" text-color="white">
                        <i class="fas fa-cogs"></i>
                        <span v-if="this.$route.params.id == null" class="h5"> Registrar Limpieza e Higiene</span>
                        <span v-else class="h5"> Actualizar Limpieza e Higiene</span>
                    </CCardHeader>
                    <CCardBody>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(verificarAccion)">
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                            <b-form-group label="Guia Principal:" class="mb-2">
                                                <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                                <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosLimpieza.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosLimpieza.idGuia" :options="listUsuarios" @search:blur="blurLimpiezaHigiene">
                                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Fecha:" class="mb-2">
                                                <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosLimpieza.fecha"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="12" class="my-2">
                                        <CButton size="sm" class="float-right" color="dark" @click="agregarListLimpieza()">
                                            <i class="fas fa-plus fa-sm"></i> Agregar
                                        </CButton>
                                    </b-col>
                                    <b-col md="12">
                                        <template>
                                            <div class="accordion" role="tablist" v-for="(opcion, k) in datosLimpieza.listPreguntas" :key="k">
                                                <b-card no-body class="mb-1">
                                                    <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                        <div block v-b-toggle="'accordion-'+k" class="bg-accordion">
                                                            <span class=" h6">{{'Limpieza e Higiene '+(k+1)}}</span>
                                                            <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                            <CButton v-if="datosLimpieza.listPreguntas.length >= 2" size="sm" class="float-right" color="danger" @click="quitarListLimpieza(k)">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </CButton>
                                                        </div>
                                                    </b-card-header>
                                                    <b-collapse visible :id="'accordion-'+k" accordion="my-accordion" role="tabpanel">
                                                        <b-card-body>
                                                            <b-row>
                                                                <b-col md="9">
                                                                    <validation-provider name="nombre del producto de limpieza" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Nombre del Producto de Limpieza:" class="mb-2">
                                                                            <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre del Producto de Limpieza" v-model.trim="opcion.pregunta"></b-form-input>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="3">
                                                                    <validation-provider name="uso" :rules="{required: false}" v-slot="validationContext">
                                                                        <b-form-group label="Uso:" class="mb-2">
                                                                            <b-form-radio-group v-model.trim="opcion.evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="observacion" :rules="{required: false}" v-slot="validationContext">
                                                                        <b-form-group label="Observación (Opcional):" class="mb-2">
                                                                            <b-form-textarea v-model.trim="opcion.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese la Observación" rows="3" max-rows="6"></b-form-textarea>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </template>
                                    </b-col>
                                    <b-col md="12" class="text-center my-2">
                                        <b-button :disabled="disabled"  variant="success" class=" mr-2" type="submit">
                                            Guardar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </CCardBody>
                </CCard>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbUsuario = firebase.firestore().collection('users');
const dbLimpiezaHigiene = firebase.firestore().collection('limpiezaHigiene');

export default {
    data() {
        return {
            listUsuarios: [],
            disabled: false,

            datosLimpieza: {
                idGuia: '',
                fecha: moment().format('YYYY-MM-DD'),
                listPreguntas: [
                    {
                        pregunta: '',
                        evaluacion: 0,
                        observacion: '',
                    }
                ],
            },

            comboSiNo: [
                {
                    value: 1,
                    text: 'Si',
                },
                {
                    value: 2    ,
                    text: 'No',
                },
            ],
        }
    },
    methods: {
        blurLimpiezaHigiene() {
            this.computedForms.refs['guia principal'].validate();
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });
        },
        agregarListLimpieza() {
            let me = this;
            me.datosLimpieza.listPreguntas.push({
                pregunta: '',
                evaluacion: 0,
                observacion: '',
            });
        },
        quitarListLimpieza(k){
            let me = this;
            me.datosLimpieza.listPreguntas.splice(k, 1);
        },
        verificarAccion() {
            if (this.$route.params.id == null) {
                this.registrarLimpiezaHigiene();
            } else if (this.$route.params.id != null) {
                this.actualizarLimpiezaHigiene();
            }
        },
        registrarLimpiezaHigiene(){
            let me = this;
            me.disabled = true;
            dbLimpiezaHigiene.add({
                idGuia: me.datosLimpieza.idGuia,
                fecha: me.datosLimpieza.fecha,
                listPreguntas: me.datosLimpieza.listPreguntas,
                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                fechaEliminar: '',
                fechaModificacion: '',
                estado: 2,
                idCliente: me.$store.state.user.idCliente.trim(),
            }).then((check) =>{
                me.swat('success', 'Registrado satisfactoriamente')
                me.$router.push({
                    name: 'Limpieza e Higiene'
                });
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!');
            });
        },
        obtenerLimpiezaHigiene() {
            let me = this;
            me.disabled = true;

            dbLimpiezaHigiene.doc(me.$route.params.id).get()
            .then((doc) => {
                me.datosLimpieza.idGuia = doc.data().idGuia;
                me.datosLimpieza.fecha = doc.data().fecha;
                me.datosLimpieza.listPreguntas = doc.data().listPreguntas;
                me.disabled = false;
            }).catch((error)=>{
                me.swat('error', 'Algo salió mal!');
                me.disabled = false;
            });
        },
        actualizarLimpiezaHigiene() {
            let me = this;
            me.disabled = true;

            dbLimpiezaHigiene.doc(me.$route.params.id).update({
                idGuia: me.datosLimpieza.idGuia,
                fecha: me.datosLimpieza.fecha,
                listPreguntas: me.datosLimpieza.listPreguntas,
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
            }).then(() => {
                me.swat('success', 'Modificado satisfactoriamente');
                me.$router.push({
                    name: 'Limpieza e Higiene'
                });
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!')
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    computed: {
        computedForms() {
            return this.$refs.observer;
        },
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            this.listarUsuarios();
            if(this.$route.params.id != null){
                this.obtenerLimpiezaHigiene();
            }
        }
    }
}
</script>